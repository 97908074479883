/* global JSX */
import sprite from '~/resources/svgs/sprite.svg';

type SvgList =
	| 'bv-logo'
	| 'ad-hijack'
	| 'google-wordmark'
	| 'google-ads-icon'
	| 'yahoo-wordmark'
	| 'ask-wordmark'
	| 'naver-wordmark'
	| 'yandex-wordmark'
	| 'aol-wordmark'
	| 'baidu-wordmark'
	| 'microsoft-bing-wordmark'
	| 'sogou-wordmark'
	| 'haosou-wordmark'
	| 'bv-icon'
	| 'bv-icon-alt'
	| 'pz-icon'
	| 'pz-icon-alt'
	| 'appswitch-icon'
	| 'reduce-commission';

type SvgIntrinsicProps = JSX.IntrinsicElements['svg'];

export type SvgProps = SvgIntrinsicProps & {
	className?: string,
	pathToSprite?: string,
	rest?: SvgIntrinsicProps,
	name: SvgList,
};

function Svg({
	className, name, pathToSprite = sprite, ...rest
}: SvgProps) {
	return (
		<svg
			className={`uic-svg--${name}${className ? ` ${className}` : ''}`}
			{...rest}
		>
			<use xlinkHref={`${pathToSprite}#${name}`} />
		</svg>
	);
}

export type { SvgList };
export { Svg as default, Svg };
